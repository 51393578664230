<!--统计报表-油烟减排统计新-->
<template>
  <section>
    <!-- 工具条 -->
    <el-col :span='24' class='toolbar' style='padding-bottom: 0px;'>
      <el-form :inline='true' :model='filter' size="mini">
        <el-form-item>
          <el-cascader
              v-model="filter.owner"
              placeholder="所属单位"
              :options="customerTree"
              :props="orgProps"
              clearable
              filterable/>
        </el-form-item>
        <el-form-item>
          <el-date-picker
              v-model="filter.peorid"
              type="daterange"
              @change="getData"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select
              v-model="filter.time"
              value-key="name"
              multiple
              collapse-tags
              @change="handleTimeSelect"
              placeholder="时间选择"
              clearable>
            <el-option
                v-for="time in timeRange"
                :key="time.name"
                :label="time.name"
                :value="time.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
              v-model="filter.columns"
              multiple
              collapse-tags
              placeholder="污染物选择"
              clearable
              @select="handleColumnSelect"
          >
            <el-option
                v-for="item in columnsList"
                :key="item.name"
                :label="item.name"
                :value="item.value"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type='primary' @click='getData'>查询</el-button>
          <el-button type='primary' @click='openExportSizeInput'>导出</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <!-- 列表 -->
    <el-col :span='24'>
      <el-table :data='datalist' size='mini' border highlight-current-row v-loading='loading'
                :max-height='clientHeight' style='width: 100%'>
        <el-table-column type='index' label='#' align='center' width='55'/>
        <el-table-column
            prop='locale_name'
            label='监测点'
            show-overflow-tooltip width='180'
            header-align='center'/>
        <el-table-column
            prop='owner_name'
            label='所属单位'
            show-overflow-tooltip width='220'
            header-align='center'/>
        <el-table-column prop='mn' label='设备编码' show-overflow-tooltip width='200' align='center'
                         header-align='center'/>
        <el-table-column
            prop='addr'
            label='地址'
            show-overflow-tooltip width='180'
            header-align='center'/>
        <el-table-column
            v-for="time in timeRange"
            :key="time.value"
            :label="time.name" v-if="time.visible" header-align='center'>
          <el-table-column
              prop='RedEmissions'
              v-if="filter.columns.includes('red_emissions')"
              label='油烟'
              align='center'
              :formatter="(row,column)=> emissionsFormatter(row,column,time.value,'red_emissions')"
              header-align='center'/>
          <el-table-column
              prop='RedPm25'
              v-if="filter.columns.includes('red_pm25')"
              label='PM2.5'
              align='center'
              :formatter="(row,column)=> emissionsFormatter(row,column,time.value,'red_pm25')"
              header-align='center'/>
          <el-table-column
              prop='RedPm10'
              v-if="filter.columns.includes('red_pm10')"
              label='PM10'
              align='center'
              :formatter="(row,column)=> emissionsFormatter(row,column,time.value,'red_pm10')"
              header-align='center'/>
          <el-table-column
              prop='RedVocs'
              v-if="filter.columns.includes('red_vocs')"
              label='VOCs'
              align='center'
              :formatter="(row,column)=> emissionsFormatter(row,column,time.value,'red_vocs')"
              header-align='center'/>
        </el-table-column>
      </el-table>
    </el-col>

    <!-- 工具条 -->
    <el-col :span='24' class='toolbar'>
      <el-pagination
          background
          @size-change="(v)=>handlePageChange(v, 'size')"
          @current-change="(v)=>handlePageChange(v, 'page')"
          :current-page.sync='filter.page'
          :page-sizes='filter.pageSizes' layout='total, sizes, prev, pager, next, jumper'
          :total='total'
          style='display:inline-block;margin-left:15px'>
      </el-pagination>
    </el-col>
  </section>
</template>

<script>
import {mapState} from 'vuex'
import {getUserInfo, ownerJoin} from '@/util'
import dayjs from "dayjs";

export default {
  name: "ReductionNew",
  data() {
    return {
      changeState: false,
      loading: false,
      datalist: [],
      timeRange: [
        {name: "0时 单位(g)", value: 0, visible: true},
        {name: "1时 单位(g)", value: 1, visible: true},
        {name: "2时 单位(g)", value: 2, visible: true},
        {name: "3时 单位(g)", value: 3, visible: true},
        {name: "4时 单位(g)", value: 4, visible: true},
        {name: "5时 单位(g)", value: 5, visible: true},
        {name: "6时 单位(g)", value: 6, visible: true},
        {name: "7时 单位(g)", value: 7, visible: false},
        {name: "8时 单位(g)", value: 8, visible: false},
        {name: "9时 单位(g)", value: 9, visible: false},
        {name: "10时 单位(g)", value: 10, visible: false},
        {name: "11时 单位(g)", value: 11, visible: false},
        {name: "12时 单位(g)", value: 12, visible: false},
        {name: "13时 单位(g)", value: 13, visible: false},
        {name: "14时 单位(g)", value: 14, visible: false},
        {name: "15时 单位(g)", value: 15, visible: false},
        {name: "16时 单位(g)", value: 16, visible: false},
        {name: "17时 单位(g)", value: 17, visible: false},
        {name: "18时 单位(g)", value: 18, visible: false},
        {name: "19时 单位(g)", value: 19, visible: false},
        {name: "20时 单位(g)", value: 20, visible: false},
        {name: "21时 单位(g)", value: 21, visible: false},
        {name: "22时 单位(g)", value: 22, visible: false},
        {name: "23时 单位(g)", value: 23, visible: false},
      ],
      columnsList: [
        {name: '油烟', value: 'red_emissions', visible: true},
        {name: 'PM2.5', value: 'red_pm25', visible: true},
        {name: 'PM10', value: 'red_pm10', visible: true},
        {name: 'VOCs', value: 'red_vocs', visible: true}
      ],
      filter: {
        peorid: null,
        time: [0, 1, 2, 3, 4, 5, 6],
        columns: ['red_emissions', 'red_pm25', 'red_pm10', 'red_vocs'],
        owner: null,
        page: 1,
        size: 20,
        pageSizes: [20, 30, 50]
      },
      total: 0
    }
  },
  computed: {
    ...mapState(['props', 'customerTree', 'clientHeight']),
    ...mapState({
      orgProps: state => Object.assign({}, state.props, {label: 'Org', checkStrictly: true})
    })
  },
  mounted() {
    this.filter.peorid = [
      dayjs().subtract(1, "month").hour(0).minute(0).second(0).format()
      , dayjs().hour(23).minute(59).second(59).format()]
    this.getData()
  },
  methods: {
    hasChange() {
      this.changeState = true
    },
    getData() {
      this.loading = true
      let userInfo = getUserInfo().Info
      var para = {
        page: this.filter.page,
        size: this.filter.size,
      }
      if (this.filter.peorid) {
        para.start_time = dayjs(this.filter.peorid[0]).unix()
        para.end_time = dayjs(this.filter.peorid[1]).hour(23).minute(59).second(59).unix()
      }
      if (this.filter.owner) {
        para.owner = ownerJoin(this.filter.owner, userInfo)
      }
      if (this.filter.columns.length > 0) {
        para.columns = this.filter.columns.join(',')
      }
      if (this.filter.time.length > 0) {
        para.hour = this.filter.time.join(',')
      }
      this.$get('admin/locale_compute', para).then(res => {
        this.datalist = res.list
        this.total = res.count
        this.loading = false
      })
    },
    handleTimeSelect() {
      for (const time of this.timeRange) {
        time.visible = this.filter.time.includes(time.value);
      }
    },
    handleColumnSelect() {
      for (const item of this.columnsList) {
        item.visible = this.filter.columns.includes(item.value)
      }
    },
    emissionsFormatter(row, column, time, type) {
      if (row.hour_list === null)
        return '-'
      let index = row.hour_list.find(item => item.hour === time)
      if (index === undefined) {
        return '-'
      } else {
        return index[type]
      }
    },
    handleRefresh() {
      this.filter.page = 1
      this.filter.name = null
      this.getData()
    },
    selsChange(sels) {
      this.sels = sels
    },
    handlePageChange(val, field) {
      this.filter[field] = val
      this.getData()
    },
    openExportSizeInput() {
      this.$prompt(`请输入导出的数据量（总量：${this.total} 条）`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: '只能填入数字'
      }).then(({value}) => {
        this.handleExport(parseInt(value))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消'
        })
      })
    },
    handleExport(size) {
      let userInfo = getUserInfo().Info
      var para = {
        page: this.filter.page,
        size: size,
        is_download: true
      }
      if (this.filter.peorid) {
        para.start_time = dayjs(this.filter.peorid[0]).unix()
        para.end_time = dayjs(this.filter.peorid[1]).hour(23).minute(59).second(59).unix()
      }
      if (this.filter.owner) {
        para.owner = ownerJoin(this.filter.owner, userInfo)
      }
      if (this.filter.columns.length > 0) {
        para.columns = this.filter.columns.join(',')
      }
      if (this.filter.time.length > 0) {
        para.hour = this.filter.time.join(',')
      }
      this.$get('admin/locale_compute', para).then(res => {
        let url = res.url
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.click()
      })
    }
  }
}
</script>
